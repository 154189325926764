import React from 'react'
import KrogerZHZW from '../../assets/partner_logos/Kroger_zhzw.png'

export default function Kroger_ZHZW() {
 return (
  <div className="max-w-sm">
   <div className="isolate grid grid-cols-1 gap-y-8 py-5 lg:max-w-none lg:grid-cols-1 lg:gap-y-0">
    <div className="flex flex-col items-center">
     <p className="text-1xl mt-2 pb-5 text-center text-lg font-bold tracking-tight text-warmTerracota-700">
      Thanks to free donations from
     </p>
     <img className="max-w-56 object-cover pl-4" src={KrogerZHZW} alt="" />
    </div>
   </div>
  </div>
 )
}
