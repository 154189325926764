import { XMarkIcon } from '@heroicons/react/20/solid'
import { NavLink } from '@remix-run/react'
import React, { useEffect, useState } from 'react'

export default function AppInstall() {

  const [showBanner, setShowBanner] = useState(false)

  useEffect(() => {
    if (typeof window !== 'undefined'
      && !window.matchMedia('(display-mode: fullscreen)').matches
      && !window.matchMedia('(display-mode: standalone)').matches)
      setShowBanner(true)
  }, [])


  return (
    <>
      {showBanner ? (
        <div className="flex items-center gap-x-6 bg-forestGreen-900 pt-8 px-6 py-2.5 sm:px-3.5 sm:before:flex-1">
          <div className="text-xs text-white">
            <NavLink
              to="/app-install"
              prefetch="viewport"
            >
              <div className='flex flex-row place-items-center'>
                <div className='min-w-8 max-w-8 min-h-8 max-h-8'>
                  <img src="/Logo_design_whitebg_192x192.png" alt="" className="w-8 h-8 object-cover rounded-md" />
                </div>
                <div className='pl-2'>
                  <strong className="font-semibold">Install the web app</strong>
                  <svg viewBox="0 0 2 2" aria-hidden="true" className="mx-2 inline h-0.5 w-0.5 fill-current">
                    <circle r={1} cx={1} cy={1} />
                  </svg>
                  <p>Stay updated with push notifications and get the best experience&nbsp;</p>
                </div>
              </div>
            </NavLink>
          </div>
          <div className="flex flex-1 justify-end">
            <button type="button" className="-m-3 p-3 focus-visible:outline-offset-[-4px]"
              onClick={() => setShowBanner(false)}
            >
              <span className="sr-only">Dismiss</span>
              <XMarkIcon aria-hidden="true" className="h-5 w-5 text-white" />
            </button>
          </div>
        </div>
      ) : null}
    </>
  )
}
